import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
new Splide('.carouselGallery__splide', {
    lazyLoad: 'nearby',
    focus: 0,
    omitEnd: true,
    perPage: 4,
    perMove: 4,
    gap: '4%',
    pagination: false,
    breakpoints: {
        950: { perPage: 2, perMove: 2 },
        700: { perPage: 1, perMove: 1 }
    }
}).mount();
const gallery = document.getElementById('carouselGallery__gallery');
if (gallery) {
    const viewer = new Viewer(gallery, {
        toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            prev: () => {
                viewer.prev(true);
            },
            next: () => {
                viewer.next(true);
            },
            download: () => {
                const a = document.createElement('a');
                a.href = viewer?.image?.src || '';
                a.download = viewer?.image?.alt || '';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
    });
}
